import { useState } from 'react';
import { EVENTS } from 'src/lib/consts/events';
import { prompt } from 'src/lib/tools/prompt';
import { track } from 'src/lib/tools/tracking';
import isValidEmail from 'src/lib/utils/valid-email';
// import newslettersImg from '/public/bestreviews.zone/newsletters.png';
import styles from './newsletters.module.scss';

export default function Newsletters() {
	const [emailValue, setEmailValue] = useState('');
	const [validEmail, setIsValidEmail] = useState(false);
	const [isSent, setSent] = useState(false);
	const [subscribeClicked, setSubscribeClicked] = useState(false);

	const onSubscribe = () => {
		const _isValidEmail = isValidEmail(emailValue);
		setIsValidEmail(_isValidEmail);
		setSubscribeClicked(true);
		if (_isValidEmail) {
			prompt.inform('newslettersAPI');
			track(EVENTS.GENERAL.SUBSCRIBE);
			setSent(true);
			setEmailValue('');
		}
	};

	const onChangeEmail = e => {
		setEmailValue(e.target.value);
		setSubscribeClicked(false);
	};

	return (
		<div className={styles.wrapper}>
			<section className={styles.newsletters}>
				<div className={styles.imgWrapper}>
					{/* <Image objectFit={'contain'} layout={'fill'} alt={'newsletters'} src={newslettersImg} /> */}
					<img alt={'newsletters'} src={'/bestreviews.zone/newsletters.png'} />
				</div>
				<div className={styles.ctaBox}>
					<h2>Best Reviews Guide Newsletter</h2>
					<h3>Get exclusive articles, recommendations, shopping tips and sales alerts</h3>
					<div className={styles.actionsWrapper}>
						<div className={styles.inputWrapper}>
							<input
								onChange={onChangeEmail}
								value={emailValue}
								type={'text'}
								autoComplete={'off'}
								className={styles.input}
								placeholder={'Enter your email here'}
							/>
						</div>
						{isSent ? <button className={styles.sent}>Thank You!</button> : <button onClick={onSubscribe}>SUBSCRIBE</button>}
					</div>
					{emailValue && !validEmail && subscribeClicked ? <div className={styles.error}>Please enter a valid email</div> : null}
				</div>
			</section>
		</div>
	);
}
