import MainLogo from '@/components/main-logo/main-logo';
import Tooltip from '@/components/tooltip/tooltip';
import { routes } from '@/consts/routes';
import styles from './footer.module.scss';
import { Link } from '@/components/link/link';
import { buildClientUrl } from 'src/lib/utils/build-url';
import { useRouter } from 'next/router';
import { trackOnNav } from 'src/lib/tools/tracking';
import { EVENTS } from 'src/lib/consts/events';

export default function Footer() {
	const { pathname } = useRouter();
	const isOwnedNote = pathname === routes.ABOUT || pathname === routes.CONTACT || pathname === routes.PRIVACY;

	return (
		<footer className={styles.footerWrapper}>
			<div className={styles.footer}>
				<div className={styles.credits}>
					<div>
						<Tooltip
							text={`Bestreviews.zone rankings are based on our own views and opinions. 
							The scoring is based on customer reviews and should not be used for accuracy purposes. 
							We are able to provide this service for free thanks to the referral fees we receive from a number of service providers. 
							These referral fees may affect the rankings and score assigned to specific vendors.
                            Though many vendors appear on the Bestreviews.zone, this DOES NOT imply endorsement of any kind. We do not accept any free products from any company. 
							The information and vendors which appear on Bestreviews.zone is subject to change at any time.`}>
							<div className={styles.disclaimer}>Advertising Disclaimer</div>
						</Tooltip>
						<MainLogo isWhite={true} />
					</div>
					<span className={styles.description}>
						When you click on links to various merchants on this site and make a purchase, this can result in this site earning
						a commission. Affiliate programs and affiliations include, but are not limited to, the eBay Partner Network and
						Amazon Associates.
					</span>
					<div className={styles.operated}>
						<span className={styles.siteName}>BestReviews.Zone</span>
						<span className={styles.rights}>© 2022 All Rights Reserved</span>
					</div>
					{isOwnedNote ? <span className={styles.owned}>Owned and Operated by Intango LTD</span> : ``}
				</div>
				<ul className={styles.links}>
					<li>
						<Link hrefCB={() => buildClientUrl(routes.PRIVACY)}>
							<span onClick={() => trackOnNav(EVENTS.CLICK.FOOTER_ITEM, { eventValueString5: 'Privacy Policy' })}>
								Privacy Policy
							</span>
						</Link>
					</li>
					<li>
						<Link hrefCB={() => buildClientUrl(routes.TERMS)}>
							<span onClick={() => trackOnNav(EVENTS.CLICK.FOOTER_ITEM, { eventValueString5: 'Terms of Service' })}>
								Terms of Service
							</span>
						</Link>
					</li>
					<li>
						<Link hrefCB={() => buildClientUrl(routes.ABOUT)}>
							<span onClick={() => trackOnNav(EVENTS.CLICK.FOOTER_ITEM, { eventValueString5: 'About' })}>About</span>
						</Link>
					</li>
					<li>
						<Link hrefCB={() => buildClientUrl(routes.CONTACT)}>
							<span onClick={() => trackOnNav(EVENTS.CLICK.FOOTER_ITEM, { eventValueString5: 'Contact' })}>Contact</span>
						</Link>
					</li>
					<li>
						<Link hrefCB={() => buildClientUrl(routes.COOKIE)}>
							<span onClick={() => trackOnNav(EVENTS.CLICK.FOOTER_ITEM, { eventValueString5: 'Cookie Declaration' })}>
								Cookie Declaration
							</span>
						</Link>
					</li>
					<li>
						<Link hrefCB={() => buildClientUrl(routes.DNSMPI)}>
							<span
								onClick={() =>
									trackOnNav(EVENTS.CLICK.FOOTER_ITEM, { eventValueString5: 'Do Not Sell My Personal Information' })
								}>
								Do Not Sell My Personal Information
							</span>
						</Link>
					</li>
				</ul>
			</div>
		</footer>
	);
}
