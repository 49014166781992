import { useState } from 'react';
import { getEntry, select } from '../../lib/tools/store/store';

export const useZ = zVar => {
	const [zStoreVar, setZStoreVar] = useState(getEntry(`${zVar}`));
	select(`${zVar}`, state => {
		if (state[`${zVar}`] !== zStoreVar) {
			setZStoreVar(state[`${zVar}`]);
		}
	});
	return zStoreVar;
};
