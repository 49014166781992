import { properCase } from "src/lib/utils/proper-case";
//TODO check if label exist, and if not add empty string
export const BoldIt = props => {
	const { label, boldLabel } = props;
	
	const renderLabel = () => {
		const properQ = properCase(boldLabel);
		const boldQ = `<b>${properQ}</b>`;
		return {
			__html: `${properCase(label).replace(properQ, boldQ)}`,
		};
	};

	return <span dangerouslySetInnerHTML={renderLabel()}></span>;
};
