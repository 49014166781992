import { LoadingIcon } from 'src/modules/icons/loading';
import { Icon } from '../icon/icon';
import styles from './loader.module.scss';

export const Loader = props => {
	const { isLoading, color = 'white' } = props;

	return (
		<Icon className={isLoading ? styles.loadingAnim : ''}>
			<LoadingIcon color={color} />
		</Icon>
	);
};
