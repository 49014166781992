import { EVENTS } from '@/consts/events';
import { Link } from '@/components/link/link';
import styles from './main-logo.module.scss';
import { routes } from 'src/lib/consts/routes';
import { buildClientUrl } from '@/utils/build-url';
import { trackOnNav } from 'src/lib/tools/tracking';

export default function MainLogo(props) {
	const { isWhite } = props;
	return (
		<Link hrefCB={() => buildClientUrl('/' + routes.HOME)}>
			<span onClick={() => trackOnNav(EVENTS.CLICK.LOGO)} className={`${styles.appLogo} ${isWhite ? `${styles.whiteLogo}` : ''}`}>
				<div role="img" alt="logo" className={styles.logoImg}></div>
			</span>
		</Link>
	);
}
