let defaultStyles = {
	fill: '#00BD9D',
	width: '24px',
	height: '24px',
};

export const ChevronRight = rest => {
	defaultStyles = {
		...defaultStyles,
		...rest,
	};

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...defaultStyles}>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
		</svg>
	);
};
