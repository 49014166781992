import styles from './autocomplete.module.scss';
import { useEffect, useRef, useState } from 'react';
import { fetchAutocomplete } from '@/api/index';
import useDebounce from '@/hooks/useDebounce';
import { inputCleaner } from 'src/lib/utils/input-cleaner';
import { searchUrl, top10UrlBest } from 'src/lib/utils/build-url';
import useOnClickOutside from 'src/modules/hooks/useOnClickOutside';
import { Icon } from '@/components/icon/icon';
import { ChevronRight } from '@/icons/chevron-right';
import { properCase } from 'src/lib/utils/proper-case';
import { getEntry, updateM } from 'src/lib/tools/store/store';
import { BoldIt } from '../bold-it/bold-it';
import { SearchIcon } from 'src/modules/icons/search';
import { Loader } from '../loader/loader';
import { EVENTS } from 'src/lib/consts/events';
import { trackOnNav } from 'src/lib/tools/tracking';
import { Link } from '../link/link';
import { routes } from 'src/lib/consts/routes';
import { WEBOX_CID } from 'src/lib/consts/general';
import { useRouter } from 'next/router';

export default function Autocomplete(props) {
	const { q, hide = true, onClickOutside = null } = props;
	const [items, setItems] = useState([]);
	const { route } = useRouter();
	const [isHide, setHide] = useState(hide);
	const [isFetched, setFetched] = useState(false);
	const autocompleteRef = useRef(null);
	const devices = getEntry('devices');
	useOnClickOutside(autocompleteRef, () => dismiss());

	const cleanQ = inputCleaner(q);
	const debouncedQ = useDebounce(cleanQ, 300);

	const dismiss = () => {
		setHide(true);
		if (onClickOutside) {
			onClickOutside();
		}
	};

	useEffect(() => {
		setHide(hide);
	}, [hide]);

	useEffect(() => {
		setItems([]);
		setFetched(false);
		if (debouncedQ && debouncedQ) {
			fetchAutocomplete({ q: debouncedQ }).then(
				result => {
					setFetched(true);
					if (result && Array.isArray(result[1])) {
						setItems(result[1].slice(0, 10));
						if (getEntry('inputFocused')) {
							setHide(false);
						}
					}
				},
				error => {
					prompt.error(`TCL ~ Autocomplete error`, error);
				}
			);
		}
	}, [debouncedQ]);

	if (!cleanQ) {
		return null;
	}

	const renderLists = () => {
		return (
			<div className={styles.listsWrapper}>
				<h4>Our Top {debouncedQ} Lists</h4>
				<ul className={styles.lists}>
					{items.length === 0 && isFetched ? (
						// no results
						<li>
							<span className={styles.notfound}>
								<div>We couldn`t find any top 10 lists matching {debouncedQ}.</div>
								{devices.isComputerDevice ? <div>Press <b>Enter</b> to search</div> : null}
							</span>
						</li>
					) : !isFetched ? (
						// loader
						<li className={styles.loaderWrapper}>
							<Loader isLoading={true} color={'#00BD9D'} />
						</li>
					) : (
						// end fetch and have results
						items.map(item => (
							<li key={item}>
								<Link hrefCB={() => top10UrlBest(item)}>
									<span
										className={styles.result}
										onClick={() => {
											trackOnNav(EVENTS.CLICK.AUTO_BEST, { keyword: debouncedQ });
											updateM('Header Loader', { isHeaderLoader: true, isSearchForm: false });
											setHide(true);
										}}>
										<BoldIt label={`Best ${item}`} boldLabel={debouncedQ} />
										<Icon>
											<ChevronRight />
										</Icon>
									</span>
								</Link>
							</li>
						))
					)}
				</ul>
			</div>
		);
	};

	const renderSearch = () => {
		const urlObj = { ...getEntry('urlParams'), q: debouncedQ };
		let filterOut = [];
		if (route === routes.HOME) {
			urlObj.cid = WEBOX_CID;
			filterOut = ['cid'];
		}
		return (
			<div className={styles.searchOption}>
				<Link href={searchUrl(urlObj, filterOut)}>
					<span
						className={styles.option}
						onClick={() => {
							setHide(true);
							updateM('Header Loader', { isHeaderLoader: true, isSearchForm: false, pages: [] });
							trackOnNav(EVENTS.CLICK.AUTO_SEARCH, { keyword: debouncedQ });
						}}>
						<BoldIt label={`Search for ${properCase(debouncedQ)}`} boldLabel={debouncedQ} />
						<Icon>
							<SearchIcon width={'20px'} height={'20px'} />
						</Icon>
					</span>
				</Link>
			</div>
		);
	};

	return (
		<div ref={autocompleteRef} className={styles.autocompleteWrapper}>
			{isHide ? null : (
				<div className={styles.autocomplete}>
					{renderSearch()}
					{renderLists()}
				</div>
			)}
		</div>
	);
}
