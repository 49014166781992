import { useState } from 'react';
import styles from './tooltip.module.scss';

export default function Tooltip(props) {
	const { children, text, ...rest } = props;
	const [show, setShow] = useState(false);

	return (
		<div className={styles.container}>
			<div className={show ? `${styles.box} ${styles.visible}` : `${styles.box}`}>
				{text}
				{/* <span className={styles.arrow}></span> */}
			</div>
			<div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} {...rest}>
				{children}
			</div>
		</div>
	);
}
