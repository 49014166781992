import config from 'src/config';
import { buildUrl } from '@/utils/build-url';
import { prompt } from '@/tools/prompt';
import { getEntry } from 'src/lib/tools/store/store';
import { DEFAULT_CID } from 'src/lib/consts/general';

/**
 * fetch autocompelte suggestions
 * @param {*} options
 * @returns
 */
export function fetchAutocomplete(options = { yid: 'fs' }) {
	return fetch(buildUrl(config.autocompleteAPI, options))
		.then(res => res.json())
		.catch(e => {
			prompt.error('error fetching autocomplete for ', options.q, e);
		});
}

/**
 * fetch top10 reviews complete suggestions
 * @param {*} options
 * @returns
 */
export function fetchTopcomplete(options = { yid: 'fs' }) {
	return fetch(buildUrl(config.topcompleteAPI, options))
		.then(res => res.json())
		.catch(e => {
			prompt.error('error fetching topcomplete for ', options.q, e);
		});
}

export function postContact(formData) {
	const urlParams = getEntry('urlParams');
	const cid = getEntry('cid');

	return fetch(buildUrl(config.contactAPI, { ...urlParams, cid }), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	})
		.then(res => res.json())
		.catch(e => {
			prompt.error('error contact', e);
		});
}

export function fetchSearch(options = {}, ua = '') {

	const params = {
		cid: DEFAULT_CID,
		maxYResults: 4,
		rlToken: 'GamarOtanuShayTheSecond',
		...options,
	};
	//TODO add ip to request
	const fetchOptions = {
		headers: {
			'user-agent': ua,
		},
	};
	return fetch(buildUrl(config.searchAPI, params), ua ? fetchOptions : {})
		.then(res => res.json())
		.catch(e => {
			prompt.error('TOP10 failed to fetch search results', params, e);
		});
}

export function fetchWrapper(promise, ms = 10000) {
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error('TIMEOUT'));
		}, ms);

		promise
			.then(value => {
				clearTimeout(timer);
				resolve(value);
			})
			.catch(reason => {
				clearTimeout(timer);
				reject(reason);
			});
	});
}