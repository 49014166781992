import { SearchIcon } from '@/icons/search';
import { CloseIcon } from '@/icons/close';
import { getEntry, updateM } from '@/tools/store/store';
import { useEffect, useState } from 'react';
import { prompt } from '@/tools/prompt';
import { EVENTS } from '@/consts/events';
import Autocomplete from '@/components/autocomplete/autocomplete';
import { track } from '@/tools/tracking';
import { useQ } from 'src/modules/hooks/useQ';
import { searchUrl } from 'src/lib/utils/build-url';
import styles from './search-form.module.scss';
import { trackOnNav } from 'src/lib/tools/tracking';
import { routes } from 'src/lib/consts/routes';
import { WEBOX_CID } from 'src/lib/consts/general';
import { useRouter } from 'next/router';

export default function SearchForm(props) {
	const { placeholder = 'Search for the best', customStyles = {} } = props;

	const categoryId = getEntry('categoryId');
	const { route } = useRouter(); 
	const q = useQ();
	const [inputValue, setInputValue] = useState('');
	const [isDeleteBtn, setIsDeleteBtn] = useState(false);
	const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);

	const onInputFocus = () => {
		updateM('Input focused', { inputFocused: true });
		track(EVENTS.GENERAL.OPEN_SEARCH);
		setAutocompleteOpen(true);
	};

	// const onSearchClick = () => {
	// 	if (!inputValue) {
	// 		return;
	// 	}
	// 	const q = inputCleaner(inputValue);

	// 	prompt.inform('fetchSearch', { q });
	// 	trackOnNav(EVENTS.GENERAL.SEARCH, { keyword: q });
	// 	const cid = getEntry('cid');
	// 	if (cid !== DEFAULT_CID) {
	// 		urlParams.cid = cid;
	// 	}
	// 	router.push(searchUrl(urlParams, ['page']));
	// };

	const onInputChange = e => {
		setInputValue(e.target.value);
		setIsDeleteBtn(Boolean(e.target.value));
	};

	const onSearchDelete = () => {
		setInputValue('');
		setIsDeleteBtn(false);
		prompt.debug('delete search term');
	};

	// TODO
	const onKeyDown = e => {
		if (e.key === 'Enter') {
			updateM('Header Loader', { isHeaderLoader: true, isSearchForm: false, pages: [], inputFocused: false });
			trackOnNav(EVENTS.CLICK.ENTER_SEARCH, { keyword: e.target.value });
			setAutocompleteOpen(false);

			const urlObj = { ...getEntry('urlParams'), q: e.target.value };
			let filterOut = [];
			if (route === routes.HOME) {
				urlObj.cid = WEBOX_CID;
				filterOut = ['cid'];
			}
			location.href = searchUrl(urlObj, filterOut);
			e.target.blur();
		} else if (e.key === 'ArrowDown') {
		} else if (e.key === 'ArrowUp') {
		}
	};

	useEffect(() => {
		if (q && !categoryId) {
			setInputValue(q);
			setIsDeleteBtn(true);
		}
	}, []);

	return (
		<div className={styles.searchAutocompleteWrapper}>
			<div className={styles.searchBar} style={customStyles.bar}>
				<div className={styles.searchBtnWrapper}>
					<SearchIcon />
				</div>
				<input
					type="text"
					autoComplete={'off'}
					value={inputValue}
					className={styles.searchInput}
					placeholder={placeholder}
					onFocus={onInputFocus}
					onChange={onInputChange}
					onKeyDown={onKeyDown}
				/>
				{/* {IS_DEBUG ? (
					<a className={styles.searchBot}>
						<BotIcon />
					</a>
				) : null} */}
				<a className={styles.searchDelete} onClick={onSearchDelete}>
					{isDeleteBtn ? <CloseIcon /> : null}
				</a>
				<div className={styles.autocomplete}></div>
				<div className={`${styles.autocomplete} ${styles.hide}`}></div>
			</div>
			<Autocomplete q={inputValue} hide={!isAutocompleteOpen} onClickOutside={() => setAutocompleteOpen(false)} />
		</div>
	);
}
