import { useEffect, useState } from 'react';
import { prompt } from 'src/lib/tools/prompt';
import { useZ } from 'src/modules/hooks/useZ';

export const Link = props => {
	const { href, hrefCB, children, ...rest } = props;
	const urlParams = useZ('urlParams');

	if (!hrefCB && !href) {
		prompt.error('No href');
	}

	const [finalHref, setFinalHref] = useState(hrefCB ? hrefCB() : href);

	useEffect(() => {
		setFinalHref(hrefCB ? hrefCB() : href);
	}, [urlParams, href]);

	return (
		<a {...rest} href={finalHref}>
			{children}
		</a>
	);
};
